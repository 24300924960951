import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import RutContainer from '../components/RutContainer';
import '../styles/RutPage.css';
import Loader from '../components/Loader';
import { Client } from '../components/Interfaces';
import { ClipLoader } from 'react-spinners';

export default function RutPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const params: URLSearchParams = new URLSearchParams(window.location.search);
  const id: string | null = params.get('id');

  const setRutFromRutContainer = (thisRut: string) => {
    setIsLoading(true);
    searchByRut(thisRut);
  };

  const searchByRut = (searchRut: string) => {
    axios.post('https://qa.almamedica.net/alma/external/pp/v1/patientData',
      { rut: searchRut },
      {
        headers: {
          Authorization: '2374dfa16c7475154d0e3539a7bcf785',
          'Content-Type': 'multipart/form-data',
        },
      }).then((response: any) => {
        if (response) {
          const data: Client = {
            nombre: response.data[0].nombre,
            paterno: response.data[0].paterno,
            materno: response.data[0].materno,
            correo: response.data[0].correo,
            celular: response.data[0].celular,
            sexo: response.data[0].sexo,
            direccion: response.data[0].direccion,
            comuna: response.data[0].comuna,
            fechaNacimiento: response.data[0].fecha_nacimiento,
            edad: response.data[0].edad,
            prevision: response.data[0].prevision,
            nacionalidad: response.data[0].nacionalidad,
            occupation: response.data[0].occupation,
            rut: searchRut,
          };
          setIsLoading(false);
          navigate('/filter', { state: { message: `${id}`, clientData: data } });
        }
      }).catch(() => {
        const data: Client = {} as Client;
        data.rut = searchRut;
        setIsLoading(false);
        navigate('/filter', { state: { message: `${id}`, clientData: data } });
      });
  };

  return (
    <div className="appBody">
      <div className="hourReservationContainer">

        {isLoading
          ? (<ClipLoader size={35} color="#5ab542" />) : <RutContainer setRutFromThis={setRutFromRutContainer} />}
      </div>
    </div>
  );
}
